import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, type ButtonProps } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetSettingsMessages } from '../../ReservationWidgetSettings.locales'

interface CopyLinkButtonProps extends ButtonProps {
  link: string
  text?: string
  'data-test': string
}

export function CopyLinkButton({ link, text, ...props }: CopyLinkButtonProps) {
  const { formatMessage } = useLocales()
  const [linkCopied, setLinkCopied] = useState(false)

  return (
    <Button
      onClick={() => {
        navigator.clipboard.writeText(link)
        setLinkCopied(true)
        setTimeout(() => {
          setLinkCopied(false)
        }, 4000)
      }}
      variant="tertiary"
      {...props}
    >
      {linkCopied
        ? formatMessage(reservationWidgetSettingsMessages.linkCopied)
        : text ?? formatMessage(reservationWidgetSettingsMessages.copyWidgetLink)}
    </Button>
  )
}
